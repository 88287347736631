import React from 'react'
import { withPrefix } from 'gatsby'
import Layout from '../../components/Layout'
import BlogRoll from '../../components/BlogRoll/BlogRoll'
import Search from '../../components/search'
const searchIndices = [{ name: `Pages`, title: `Pages` }]

export default class BlogIndexPage extends React.Component {
    render() {
        return (
            <Layout>
                <div
                    className="full-width-image-container margin-top-0"
                    style={{
                        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), url(${withPrefix("/") + "img/ui-app2.jpg"})`,
                        height: '20em'
                    }}
                >
                    <div className="rows is-full">
                        <div className="row">
                            <div className="columns">
                                <div className="column">
                                    <h2
                                        className="is-size-3-mobile is-size-2-tablet is-size-1-widescreen has-text-centered"
                                        style={{
                                            boxShadow:
                                                '#49509D 0.5rem 0px 0px, #49509D -0.5rem 0px 0px',
                                            backgroundColor: '#49509D',
                                            color: 'white',
                                            lineHeight: '1',
                                            padding: '0.25em',
                                            borderRadius: '20px',

                                        }}
                                    >
                                        Dev Blog
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="columns">
                                <div className="column">
                                    <Search
                                        indices={searchIndices} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="section">
                    <div className="container">
                        <div className="content">
                            <BlogRoll />
                        </div>
                    </div>
                </section>
            </Layout>
        )
    }
}
