import React from "react"
import { connectSearchBox } from "react-instantsearch-dom"
import { Search } from "@styled-icons/fa-solid"
import styled from "styled-components";

const StyledSearchIcon = styled(Search)`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  color: "black";
  cursor: pointer;
`;

const SearchBoxWithIcon = ({ refine, currentRefinement, className, onFocus }) => (
  <form className={className}>
    <input
      className="SearchInput"
      type="text"
      placeholder="Angular..."
      aria-label="Search"
      onChange={e => refine(e.target.value)}
      value={currentRefinement}
      onFocus={onFocus} 
    />
    <StyledSearchIcon />
  </form>
);

export default connectSearchBox(SearchBoxWithIcon);