import styled, { css } from "styled-components"
import SearchBox from "./search-box"

const open = css`
  width: 100%;
  background: ${({ theme }) => theme.background};
  cursor: text;
  border: none;
  margin-left: -2em;
  padding-left: 1em;
  padding-bottom: 8px;
  padding-top: 8px;
  border-radius: 20px;
  font-size: 1.5em;
  ::placeholder {
    color: black;
  }
`

const closed = css`
  width: 100%;
  background: #6A70B2;
  border: none;
  cursor: pointer;
  font-size: 1.5em;
  margin-left: -2em;
  padding-left: 1em;
  padding-bottom: 8px;
  padding-top: 4px;
  color: antiquewhite;
  border-radius: 20px;
`

export default styled(SearchBox)`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-bottom: 0;
  min-height: 40px;
  text-align: start;

  .SearchInput {
    outline: none;
    font-size: 1em;
    transition: 100ms;
    color: ${({ theme }) => theme.foreground};
    ::placeholder {
      color: ${({ theme }) => theme.faded};
    }
    ${({ hasFocus }) => (hasFocus ? open : closed)}
  }
`